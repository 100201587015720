






































































































import { InputSetups } from '@/mixins/input-setups'
import ModuleBindings from '@/includes/logic/Modules/mixins/ModuleBindings'
import StopWordFilterHandlerModuleBuilder
  from "@/includes/logic/Modules/models/modules/filters/StopWordFilterHandlerModule/StopWordFilterHandlerModuleBuilder";

import ModuleConfigSettings from '../../components/ModuleConfigSettings.vue'
import ModuleConfigSettingsWrapper from '../../components/ModuleConfigSettingsWrapper.vue'
import FilterJournal from "../../components/filters/FilterJournal.vue";
import FilterCommonSettings from "../../components/filters/FilterCommonSettings.vue";
import FilterNewUsersSettings from "../../components/filters/FilterNewUsersSettings.vue";
import FilterRemoveEditedSetting from "../../components/filters/FilterRemoveEditedSetting.vue";
import FilterExtraSettings from "../../components/filters/FilterExtraSettings.vue";
import FilterResetPunishSettings from '../../components/filters/FilterResetPunishSettings.vue'
import FilterSettingLayout from "../../components/filters/layout/FilterSettingLayout.vue";
import HandleUserBioField from "../../components/filters/fields/HandleUserBioField.vue";

import ChipsInput from 'piramis-base-components/src/components/Pi/fields/ChipsInput/ChipsInput.vue'
import SwitchInput from 'piramis-base-components/src/components/Pi/fields/SwitchInput/SwitchInput.vue'
import { TagData } from 'piramis-base-components/src/components/Tags/types';

import { Component, Mixins, VModel } from 'vue-property-decorator'

@Component({
  components: {
    HandleUserBioField,
    FilterSettingLayout,
    FilterExtraSettings,
    FilterRemoveEditedSetting,
    FilterResetPunishSettings,
    FilterNewUsersSettings,
    FilterCommonSettings,
    FilterJournal,
    ModuleConfigSettingsWrapper,
    ModuleConfigSettings,
    ChipsInput,
    SwitchInput,
  },
})
export default class StopWordFilterHandlerModuleView extends Mixins(InputSetups, ModuleBindings) {
  @VModel() module!: StopWordFilterHandlerModuleBuilder

  get stopWordListTagHelpMessage(): Array<TagData> {
    return [
      {
        text: this.$t('field_stop_words_tag_help_message_title').toString(),
        color: 'blue',
        hint: this.$t('field_stop_words_tag_help_message').toString()
      }
    ]
  }

  formatter(val: string) {
    const reg = /^\*.*.\*$/
    const trimmedValue = val.trim()

    if (reg.test(trimmedValue)) {
      return `<span class='text-danger font-medium'>*</span>${ trimmedValue.substring(1, val.length - 1) }<span class="text-danger font-medium">*</span>`
    }

    return val
  }

}
