



























import TariffsTagsHelper from "@/mixins/TariffsTagsHelper";
import CommonFilterSettings from "@/includes/logic/Modules/models/filters/CommonFilterSettings";
import { InputSetups } from "@/mixins/input-setups";

import SwitchInput from 'piramis-base-components/src/components/Pi/fields/SwitchInput/SwitchInput.vue';

import { Component, Mixins, VModel } from 'vue-property-decorator';

@Component({
  components: {
    SwitchInput
  }
})
export default class HandleUserBioField extends Mixins(TariffsTagsHelper, InputSetups) {
  @VModel() handle_user_bio!: CommonFilterSettings['handle_user_bio']
}
